
.board-users-main {
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dashboard-main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  /* border: 1px solid red; */
  gap: 10px;
  width: 95%;
  margin: 0 auto;
}

.dropdown-board-main{
  /* border: 1px solid red; */
  margin: auto;
}

/* YourStyles.module.css */
.dropdown-board-main {
  position: relative;
  display: inline-block;
}

.three-dots {
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  /* border: 1px solid red; */
  width: 150px;
}

.dropdown-option {
  padding: 10px;
  cursor: pointer;
}

.dropdown-option:hover{
  background-color: rgb(178, 177, 177);
}

/* Show the dropdown content when the three dots are clicked */
.dropdown-board-main:hover .dropdown-content {
  display: block;
}


.board-users-main{
    /* border: 1px solid red; */
    margin: 5px;
}

.react-datepicker {
  font-family: 'Arial', sans-serif;
}

.Inp-date-picker{
    /* border: 1px solid red; */
    text-indent: 15px;
    border: 2px solid #ffffff;
}

.date-icon-board{
    /* border: 1px solid red; */
    position: absolute;
    z-index: 1000;
    margin-top: 9px;
    margin-left: 5px;
    font-size: 20px;
    color: #C2CDDF;
}

.date-board-main{
    /* border: 1px solid black; */
    display: flex;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .dashboard-main {
    max-width: 80%;
  }
}

@media (max-width: 576px) {
  .dashboard-main {
    max-width: 90%;
  }
}

/* Dashboard.module.css */

/* Dashboard.module.css */


.recharts-wrapper {
  font-family: 'Arial', sans-serif;
}

.recharts-bar {
  fill: #8884d8;
}

.recharts-cartesian-axis-tick {
  font-size: 14px;
}

.recharts-cartesian-axis-tick > text {
  fill: #666;
}

.recharts-tooltip {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-size: 14px;
}

.recharts-tooltip-label {
  color: #333;
  font-weight: bold;
}

.recharts-tooltip-item {
  color: #888;
  margin-bottom: 5px;
}

.recharts-tooltip-item > span {
  font-weight: bold;
  margin-right: 5px;
}



/* Slider Styles */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
  }
  
  .slick-list {
    position: relative;
    overflow: hidden;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .slick-slide {
    transition: transform 0.3s ease-in-out;
  }
  
  .main-board-cards {
    padding: 20px;
  }
  
  /* Navigation Dots Styles */
  .slick-dots {
    position: absolute;
    bottom: 10px;
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
  
  .slick-dots li {
    margin: 0 5px;
  }
  
  .slick-dots button {
    font-size: 12px;
    line-height: 1;
    color: #999;
    padding: 5px;
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
  }
  
  .slick-dots button:hover,
  .slick-dots button:focus {
    color: #555;
  }

  .slick-next {
    /* border: 2px solid red; */
  }
  

  .main-board-cards {
    /* margin: 20px; */
    padding: 20px;
    /* margin: 5px; */
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 15px;
    margin-top: 15px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* border: 1px solid blue; */
  }

/* Styles for Second Board */
.second-board-main {
  display: grid;
  /* gap: 20px; */
  /* margin: 20px; */
  margin-bottom: 10px;
  grid-template-columns: 50% 50%;
  /* border: 1px solid red; */
}

.large-card {
  /* background: linear-gradient(109.6deg, rgb(218, 185, 252) 11.2%, rgb(125, 89, 252) 91.1%);  */
  background-color: #ffffff;
  border-radius: 8px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  /* padding: 30px; */
  text-align: center;
  transition: all 0.3s ease !important;
  /* margin-top: 5px; */
  /* margin-right: 10px; */
  margin: 5px;
  /* border: 1px solid rgb(235, 10, 10); */
}


.user-statistics-chart {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sub-head-data{
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;
}

.pie-main {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* border: 1px solid red; */
}

.pie-main .icon-container {
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
}

.pie-main .icon-container p {
  margin-right: 10px;
  font-size: 15px;
}

.pie-main .pie-text {
  margin: 0;
}

/* Add more styling as needed */


.small-main-one{
  /* border: 1px solid red; */
}
  
.small-card {
    display: flex;
    align-items: center;
    background-color: white; 
      /* background-image: url(../../../Assets/BG\ 1.png); */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 50px;
    transition: all 0.3s ease;
    /* border: 1px solid red; */
    margin-top: 5px;
    color: black;
    font-weight: 600;
  }
  
  .small-card img{
    height: 100px;
  }

  .large-card img {
    max-width: 100px; 
    border-radius: 50%; 
    margin-right: 20px; 
  }
  
  .small-card p {
    flex: 1; 
    text-align: left; 
  }

  .pie-no-data{
    /* border: 1px solid blue; */
    position: relative;
  }

  .pie-no-data img{
    /* border: 1px solid red; */
    width: 100%;
  }

  .pie-main{
    /* border: 1px solid red; */
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    padding: 10px;
    /* margin: auto; */
    padding-top: 43px;
  }

  /* .pie-main:hover{
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  } */

  /* Add these styles to your existing stylesheet or component's CSS module */

/* Container for each line */
.icon-container {
  display: flex;
  align-items: center; /* Align items vertically in the center */
}

.pie-text{
  color: gray;
}

/* Icon style */
.icon-one {
  margin-right: 10px; /* Adjust margin as needed */
  margin-top: 5px;
  color: #45B0DC;
}

.icon-two {
  margin-right: 10px; /* Adjust margin as needed */
  margin-top: 5px;
  color: #8F9095;
}

.icon-three {
  margin-right: 10px; /* Adjust margin as needed */
  margin-top: 5px;
  color: #20A0FF;
}

.icon-four {
  margin-right: 10px; /* Adjust margin as needed */
  margin-top: 5px;
  color: #29A525;
}

/* Optional: Increase space between lines */
.icon-container + .icon-container {
  margin-top: 8px; /* Adjust margin as needed */
}

  
  /* .small-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  } */

  .bar-main{
    /* border: 1px solid red; */
    transition: all 0.3s ease;
  }
  

  .Dashboard_large-card__hsDIL{
    /* border: 1px solid red; */
  }
  
  /* .large-card:hover {
    transform: translateY(-5px) !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important; 
    transition: all 0.3s ease !important;
  } */
  

  .bar-graph {
    display: flex;
    flex-direction: column;
    margin-top: 10px; 
    gap: 20px;
  }
  
  .bar {
    position: relative;
    background-color: rgb(196, 225, 248);
    color: #fff; 
    width: 30px;
    text-align: left;
    padding: 10px;
    margin-right: 10px;
    transition: width 0.3s ease;
    border-radius: 5px;
    height: 5px;
    font-weight: 600;
  }
  
  .bar p {
    margin: 0;
  }
  
  .bar p:hover::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    display: inline-block;
  }
  

/* Styles for Second Board */
.second-board-main {
  
    @media (max-width: 768px) {
      grid-template-columns: 1fr; 
    }
  }
  
  /* Responsive styles for smaller screens */
  @media (max-width: 768px) {
    .small-card,
    .large-card {
      margin-bottom: 20px;
    }

    .manufac-img{
      height: 50px;
      /* margin-right: 20px; */
      /* border: 1px solid red; */
    }
  }
  
  /* <p>Paid Users: {totalPaidUsers}</p> */

  /* background-color: #061739; */
  
  .slick-next:before{
    /* border: 1px solid red !important; */
  }

  /* Add this CSS to your stylesheet */
  .pie-chart {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: conic-gradient(transparent 50%, #E38627 0 0%, #C13C37 0% 0%, #6A2135 0% 0%);
    transform: rotate(-90deg);
    margin: 20px auto;
  }
  
  /* Styles for the tooltip */
  .pie-slice {
    position: absolute;
    width: 50%;
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    transform-origin: 100%;
  }

  /* Dashboard.module.css */
.dropdown-board-main select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  background-color: white; /* Adjust the background color as needed */
}

.dropdown-board-main select:hover {
  border-color: #aaa;
}

.dropdown-board-main select:focus {
  border-color: #5b9dd9;
  box-shadow: 0 0 0 3px rgba(91, 157, 217, 0.3);
}

/* Define styles for the container */
.users-secondary-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  /* border: 1px solid red; */
  margin: 20px;
}

/* Define styles for individual cards */
.sec-card-one,
.sec-card-two,
.sec-card-three {
  flex-basis: calc(33.33% - 10px);
  background-color: #fff;
  /* border: 1px solid #fb0000; */
  border-radius: 20px;
  /* padding: 30px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  margin: 5px;
}

.sec-card-one h3,
.sec-card-two h3,
.sec-card-three h3 {
  /* margin-top: 0; */
  font-size: 30px;
  color: white;
  margin: 5px;
}

.sec-card-one p,
.sec-card-two p,
.sec-card-three p {
  /* margin-bottom: 0; */
  
}

.registration-text{
  margin: 5px;
  font-size: 50px;
  font-weight: 600;
  color: #355BA1;
}

.pendingpayment-text{
  margin: 5px;
  font-size: 50px;
  font-weight: 600;
  color: #355BA1;
}

.pendingtickets-text{
  margin: 5px;
  font-size: 50px;
  font-weight: 600;
  color: #355BA1;
}

.text-sec-card{
  margin: 5px;
  font-size: 15px !important;
  color: gray !important;
  font-weight: 300 !important;
  /* border: 1px solid red; */
}

.sec-card-one{
  display: flex;
  justify-content: space-between;
}

.sec-card-two{
  display: flex;
  justify-content: space-between;
}

.sec-card-three{
  display: flex;
  justify-content: space-between;
}

.inside-sec-one-card{
  /* border: 1px solid red; */
  background-color: #355BA1;
  padding: 30px;
  border-bottom-right-radius: 95px;
  /* border-top-right-radius: 20px; */
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 60px;
}

.inside-sec-one-two-card{
  /* border: 1px solid rgb(1, 0, 0); */
  border-radius: 15px;
  text-align: end;
}

.dates-select-main-one{
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 10px;
}

.custom-legend{
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-evenly;
}

.legend-item{
  /* border: 1px solid red; */
  /* display: flex; */
}

.legend-value{
  color: #355BA1;
}

.pie-and-cards{
  border: 1px solid red;
}

.graphs-heading{
  /* border: 1px solid red; */
  text-align: center;
  font-size: 10px;
  /* color: gray; */
  color: #0077b6;
}

.bill-stats-data{
  /* border: 1px solid red; */
  font-size: 20px;
  margin: 10px;
  color: #0077b6;
}

.pie-order-stats{
  /* border: 1px solid red; */
  padding: 23px;
}

.table-container {
  height: 20rem;
  overflow-y: auto;
  padding: 20px;
}

.bill-table {
  width: 100%;
  border-collapse: collapse;
}

.bill-table th,
.bill-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #dddddd;
}

.bill-table th {
  font-weight: bold;
}


.bill-table td {
  font-size: 14px;
  color: rgb(123, 123, 123);
  cursor: pointer;
}

.bill-table tr:hover{
  background-color: rgb(235, 235, 235);
}

.bill-stats-data {
  font-size: 18px;
  margin-bottom: 10px;
}

.dashrec-notfound{
  /* border: 1px solid red; */
  margin-top: 100px;
  padding: 20px;
  font-size: 25px;
  color: rgb(196, 196, 196);
}